import React, { useState, useEffect, useContext, useRef, useMemo, Fragment } from 'react';
import { Box, Typography, Button, Link as MuiLink } from '@mui/material';
import { styled } from '@mui/system';
import { useNavigate, Link, useSearchParams } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import Loading from 'components/Loading';
import { useSpace } from 'contexts/space_v2.context';
import useGetActionSum from 'api/statistics/asset/useGetActionSum';
import useDetectLast from 'hooks/useDetectLast';
import getFromToGroupby from 'views/statistics/getFromToGroupby';
import { iso_datetime_pipe, item_name_pipe, number_format_pipe } from 'pipes';
import Filter from './Filter';
import ItemDetailDialog from 'components/item/detail/ItemDetailDialog';
import Nodata from 'components/Nodata';
import { format } from 'date-fns';



const ActionSumPage = () => {
    const space = useSpace();
    const [searchParams, setSearchParams] = useSearchParams();
    
    const params = useMemo(() => {
        if (!space) return null;
        const keyword = searchParams.get('keyword') || '';
        const storeOrRelease = searchParams.get('storeOrRelease') || 'store';
        const placeId = searchParams.get('placeId');
        const startDate = searchParams.get('startDate');
		const endDate = searchParams.get('endDate');

        const { from_a, to_a, fromDate, toDate } = getFromToGroupby('day', startDate, endDate);
        
        return ({
            spaceId: space.id,
            keyword,
            storeOrRelease,
            placeId,
            startDate: iso_datetime_pipe(fromDate),
            endDate: iso_datetime_pipe(toDate),
        });
    }, [ searchParams, space ]);
    const [ pageTrigger, setPageTrigger ] = useState();

    const { loading, rows, resultParams, mayMore, fetched } = useGetActionSum(params, pageTrigger);

    const lastElementRef = useDetectLast(loading, mayMore, setPageTrigger);

    const resultDescribe = useMemo(() => {
        if (!fetched) return '';

        const { startDate, endDate, storeOrRelease, placeId } = resultParams;
        const from = format(new Date(startDate), 'yyyy-MM-dd');
        const to = format(new Date(endDate), 'yyyy-MM-dd');
        
        const fromto_t = `${from} ~ ${to}`;
        let text = `기간: ${fromto_t}\n구분: ${storeOrRelease === 'store' ? '매입' : '매출'}`;
        
        if (placeId) {
            const place = space.places.find(el => el.id == placeId);
            if (place) {
                text += `\n창고: ${place.name}`;
            }
        }
        
        return text;
    }, [ fetched, resultParams, space ]);

    // item
	const [ itemSelected, setItemSelected ] = useState(null);
	const [ itemDetailOpen, setItemDetailOpen ] = useState(false);
	const openItemDetail = (item) => { 
		if (item) {
			setItemSelected(item);
			setItemDetailOpen(true);
		}
	}

	return (
		<Box>
            <Filter searchDisabled={loading} />

            {fetched && <>
                <Box sx={{ mt: 4, mb: 2 }}>
                    <Typography variant='body2' sx={{ whiteSpace: 'pre-line' }}>{resultDescribe}</Typography>
                </Box>
            
                <div className="mt-1 table-responsive">
                    <table className="table table-sm table-bordered" style={{ whiteSpace: 'nowrap' }}>
                        <thead>
                            <tr>
                                <th scope="col">제품</th>
                                <th scope="col">가격</th>
                                <th scope="col">{resultParams.storeOrRelease === 'store' ? '매입' : '매출'}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {rows.map((row, index) => <tr key={row.id} ref={index === rows.length - 1 ? lastElementRef : null}>
                                <td style={{ textAlign: 'start' }}><MuiLink sx={{ cursor: 'pointer' }} onClick={() => openItemDetail(row)}>{item_name_pipe(row)}</MuiLink></td>
                                <td style={{ textAlign: 'end'}} >{number_format_pipe(row.price)}</td>
                                <td style={{ textAlign: 'end'}} >{number_format_pipe(row.sum)}</td>
                            </tr>)}
                        </tbody>
                    </table>

                    {rows.length === 0 && <Nodata>{'데이터가 없습니다.'}</Nodata>}
                </div>
            </>}

            <ItemDetailDialog open={itemDetailOpen} setOpen={setItemDetailOpen} id={itemSelected?.id} />
		</Box>
	);
}

export default ActionSumPage;