import React, { useContext, useState } from 'react';
import { Breadcrumbs, Link as MuiLink, Typography, Toolbar, IconButton, Drawer, CssBaseline, Box, AppBar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Loading from 'components/Loading';
import useReactRouterBreadcrumbs from 'use-react-router-breadcrumbs';
import routes from 'Routes';
import colors from 'constants/colors';
import AuthMenuButton from './AuthMenuButton';
import SidebarMenus from './SidebarMenus';
import { Link, Outlet } from 'react-router-dom';
import { AuthContext } from 'contexts/auth.context';
import NeedSignin from 'components/NeedSignin';
import { SpaceProvider, useSpace } from 'contexts/space_v2.context';


const drawerWidth = 180;

function MainLayout(props) {
    const { me } = useContext(AuthContext);

    const space = useSpace();

    const breadcrumbs = useReactRouterBreadcrumbs(routes, { disableDefaults: true });

    const { window } = props;
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const container = window !== undefined ? () => window().document.body : undefined;

    if (!me) return (<NeedSignin />);
    if (!space) return (<Loading />);

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar
                position="fixed"
                sx={{
                    width: { md: `calc(100% - ${drawerWidth}px)` },
                    ml: { md: `${drawerWidth}px` },
                    background: colors.lime,
                }}
            >
                <Toolbar>
                    <IconButton
                        color="primary"
                        edge="start"
                        onClick={handleDrawerToggle}
                        sx={{ mr: 2, display: { md: 'none' } }}
                    >
                        <MenuIcon />
                    </IconButton>
                    
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                        <MuiLink component={Link} noWrap variant='h6' to={'/'} style={{ fontFamily: "Audiowide", color: colors.primaryDark, fontWeight: 'bold', textDecoration: 'none' }}>{'Antpocket'}</MuiLink>
                        
                        <AuthMenuButton />
                    </Box>
                </Toolbar>
            </AppBar>
            
            <Box
                component="nav"
                sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 } }}
            >
                <Drawer
                    container={container}
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    >
                    <SidebarMenus setNavigationOpen={setMobileOpen} />
                </Drawer>

                <Drawer
                    variant="permanent"
                    sx={{
                        display: { xs: 'none', md: 'block' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                    open
                >
                    <SidebarMenus />
                </Drawer>
            </Box>
            <Box
                component="main"
                sx={{ flexGrow: 1, p: { xs: 1, md: 3 }, width: { md: `calc(100% - ${drawerWidth}px)` } }}
            >
                <Toolbar />
                
                <Breadcrumbs>
                    {breadcrumbs.map(({ match, breadcrumb }) => (
                        // <MuiLink key={match.pathname} underline="hover" color="inherit" variant='body2' href={match.pathname}>{breadcrumb}</MuiLink>
                        <Typography key={match.pathname} variant='body2'>{breadcrumb}</Typography>
                    ))}
                </Breadcrumbs>

                <Box sx={{ mt: 3 }}>
                    <Outlet />
                </Box>
            </Box>
        </Box>
    );
}

export default function() {
    return (
        <SpaceProvider>
            <MainLayout />
        </SpaceProvider>
    )
}