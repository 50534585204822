import API from "api";
import { createContext, useContext, useEffect, useReducer, useState } from "react";


const SpaceContext = createContext(null);
const SpaceDispatchContext = createContext(null);
const FetchSpaceContext = createContext(null);


export function SpaceProvider({ children }) {
    const [ spaceValue, dispatch ] = useReducer(reducer, null);

    const [trigger, setTrigger] = useState();
    useEffect(() => {
        API.get(`/space/selected`)
        .then(data => {
            dispatch({
                type: 'fetched',
                value: data
            });
        });
    }, [ trigger ]);
    const fetchSpace = () => { setTrigger(new Date().getTime() )}

    return (
        <SpaceContext.Provider value={spaceValue}>
            <FetchSpaceContext.Provider value={fetchSpace}>
                <SpaceDispatchContext.Provider value={dispatch}>
                    {children}
                </SpaceDispatchContext.Provider>
            </FetchSpaceContext.Provider>
        </SpaceContext.Provider>
    )
}

export function useSpace() {
    return useContext(SpaceContext);
}

export function useFetchSpace() {
    return useContext(FetchSpaceContext);
}

export function useSpaceDispath() {
    return useContext(SpaceDispatchContext);
}


function reducer(space, action) {
    switch (action.type) {
        case 'fetched': {
            const { value } = action;

            return value;
        }

        case 'modified': {
            const { value } = action;

            return ({
                ...value,
            })
        }

        case 'plan_modified': {
            const { value } = action;

            return ({
                ...space,
                plan: value,
            })
        }

        case 'place_added': {
            const { value } = action;

            const places = [
                ...space.places,
                value
            ];

            return ({
                ...space,
                places,
            });
        }

        case 'place_modified': {
            const { id, value } = action;

            const idx = space.places.findIndex(el => el.id == id);

            if (idx > -1) {
                const places = [
                    ...space.places,
                ];
                places.splice(idx, 1, value);
                
                return ({
                    ...space,
                    places,
                });
            }
            else return space;
        }

        case 'place_removed': {
            const { id } = action;

            const idx = space.places.findIndex(el => el.id == id);

            if (idx > -1) {
                const places = [
                    ...space.places,
                ];
                places.splice(idx, 1);
                
                return ({
                    ...space,
                    places,
                });
            }
            else return space;
        }

        case 'trader_added': {
            const { value } = action;

            const traders = [
                ...space.traders,
                value
            ];

            return ({
                ...space,
                traders,
            });
        }

        case 'trader_modified': {
            const { id, value } = action;

            const idx = space.traders.findIndex(el => el.id == id);

            if (idx > -1) {
                const traders = [
                    ...space.traders,
                ];
                traders.splice(idx, 1, value);
                
                return ({
                    ...space,
                    traders,
                });
            }
            else return space;
        }

        case 'trader_removed': {
            const { id } = action;

            const idx = space.traders.findIndex(el => el.id == id);

            if (idx > -1) {
                const traders = [
                    ...space.traders,
                ];
                traders.splice(idx, 1);
                
                return ({
                    ...space,
                    traders,
                });
            }
            else return space;
        }

        case 'category_added': {
            const { value } = action;

            const categories = [
                ...space.categories,
                value
            ];

            return ({
                ...space,
                categories,
            })
        }

        case 'category_modified': {
            const { id, value } = action;

            const idx = space.categories.findIndex(el => el.id == id);
            
            if (idx > -1) {
                const categories = [
                    ...space.categories,
                ]
                categories.splice(idx, 1, value);

                console.log(categories);

                return ({
                    ...space,
                    categories
                });
            }
            else return space;
        }

        case 'category_removed': {
            const { id } = action;

            const idx = space.categories.findIndex(el => el.id == id);
            
            if (idx > -1) {
                const categories = [
                    ...space.categories,
                ]
                categories.splice(idx, 1);

                return ({
                    ...space,
                    categories
                });
            }
            else return space;
        }

        case 'prop_added': {
            const { categoryId, value } = action;

            const categoryIdx = space.categories.findIndex(el => el.id == categoryId);
            
            if (categoryIdx > -1) {
                const category = space.categories[categoryIdx];
                category.props.push(value);

                const categories = [
                    ...space.categories,
                ]
                categories.splice(categoryIdx, 1, category);

                return ({
                    ...space,
                    categories,
                });
            }
            else return space;
        }

        case 'prop_modified': {
            const { categoryId, propId, value } = action;

            const categoryIdx = space.categories.findIndex(el => el.id == categoryId);
            
            if (categoryIdx > -1) {
                const category = space.categories[categoryIdx];

                const propIdx = category.props.findIndex(el => el.id == propId);
                if (propIdx > -1) {
                    const props = [
                        ...category.props
                    ];
                    props.splice(propIdx, 1, value);

                    const category_m = {
                        ...category,
                        props
                    };

                    const categories = [
                        ...space.categories,
                    ]
                    categories.splice(categoryIdx, 1, category_m);

                    return ({
                        ...space,
                        categories,
                    })
                }
                else return space;
            }
            else return space;
        }

        case 'prop_removed': {
            const { categoryId, propId } = action;

            const categoryIdx = space.categories.findIndex(el => el.id == categoryId);
            
            if (categoryIdx > -1) {
                const category = space.categories[categoryIdx];

                const propIdx = category.props.findIndex(el => el.id == propId);
                if (propIdx > -1) {
                    const props = [
                        ...category.props
                    ];
                    props.splice(propIdx, 1);

                    const category_m = {
                        ...category,
                        props
                    };

                    const categories = [
                        ...space.categories,
                    ]
                    categories.splice(categoryIdx, 1, category_m);

                    return ({
                        ...space,
                        categories,
                    })
                }
                else return space;
            }
            else return space;
        }

        default: throw Error('Unknown actions: ', action.type);
    }
}