const AuthCode = {
    appAdmin: 'app_admin',

    spaceOwner: 'space.owner',
    schemaWritable: 'schema.writable',

    actionStore: 'action.store',
    actionRelease: 'action.release',
    actionAdjustment: 'action.adjustment',
    actionMove: 'action.move',
    actionConfirmable: 'action.confirmable',

    assetView: 'asset.view',
};

const spaceAuthCodes = [
    { key: 'space.owner', name_kr: '스페이스 소유', describe_kr: '소유자로서 결제계정을 가지고 있습니다.' },
    { key: 'schema.writable', name_kr: '데이터 관리', describe_kr: '거래처, 창고, 제품, 제품 속성을 등록, 수정, 삭제 할 수 있습니다.' },
    { key: 'action.store', name_kr: '액션: 입고', describe_kr: '작성된 액션을 승인할 수 있습니다.' },
    { key: 'action.release', name_kr: '액션: 출고', describe_kr: '입고 액션을 작성할 수 있습니다.' },
    { key: 'action.adjustment', name_kr: '액션: 조정', describe_kr: '출고 액션을 작성할 수 있습니다.' },
    { key: 'action.move', name_kr: '액션: 이동', describe_kr: '이동 액션을 작성할 수 있습니다.' },
    { key: 'action.confirmable', name_kr: '액션 승인', describe_kr: '조정 액션을 작성할 수 있습니다.' },
    { key: 'asset.view', name_kr: '자산 데이터 조회', describe_kr: '자산(제품 가격) 관련 데이터를 조회할 수 있습니다.' },
];





module.exports = {
    AuthCode,
    spaceAuthCodes,
};