import React, { useState, useEffect, useContext, useMemo, useRef } from 'react';
import { Box, Dialog, DialogTitle, DialogActions, DialogContent, Stack, Button } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import { useFormik } from 'formik';
import * as yup from 'yup';
import API from 'api';
import colors from 'constants/colors';
import CircularButton from 'components/CircularButton';
import ValueInput from './ValueInput';
import { Regex_Code } from 'constants/regex.constants';
import { useSpace } from 'contexts/space_v2.context';
import PlaceInput from './PlaceInput';
import PageContext from 'contexts/page.context';
import ErrorText from 'components/ErrorText';


const LabelPx = '96px';

const ItemFormModal = ({ open, setOpen, itemId }) => {
    const { showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const { category, setRows, reload } = useContext(PageContext);

    // 설정가능 Props
    const props = useMemo(() => {
        if (!space || !category) return;
        return space.categories.find(el => el.id === category.id).props;
    }, [ space, category ]);

    const [item, setItem] = useState(null);
    useEffect(() => {
        if (itemId && open) {
            API.get(`/item/${itemId}`)
            .then(setItem);
            return () => setItem(null);
        }
    }, [ itemId, open ]);

    useEffect(() => {
        if (open) {
            setTimeout(() => {
                nameInputRef.current?.focus();
            }, 300);
        }
    }, [ open ]);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            id: item?.id,
            spaceId: space?.id,
            categoryId: category?.id,
            name: item?.name || '',
            code: item?.code || '',
            price: item?.asset?.price || '',
            order: item?.order || '',
            props: item?.props || [],
            places: !item ? space.places.map(place => ({
                placeId: place.id,
                placeName: place.name,
                amount: '',
            })) : [],
        },
        validationSchema: yup.object().shape({
            id: item ? yup.number().required() : yup.number().nullable(),
            name: yup.string().required('필수입력입니다.').max(64, '최대 64자'),
            code: yup.string().nullable().max(64, '최대 64자').matches(Regex_Code.regex, Regex_Code.desc),
            order: item ? yup.number().integer('정수를 입력해주세요.').required('필수입력입니다.') : yup.number().nullable(),
        }),
        onSubmit: (values, { setSubmitting, resetForm }) => {
            const values_a = values.props.map(prop => prop.values);
            values['values'] = values_a;
            if (!item) {
                values['placeItemAmounts'] = [];
                for (let place of values['places']) {
                    if (place.amount.trim() === '') continue;
                    values['placeItemAmounts'].push({
                        placeId: place.placeId,
                        amount: +place.amount,
                    });
                }
                
                const body = {
                    spaceId: space.id,
                    data: {
                        ...values,
                        price: values.price ? +values.price : null
                    },
                }
                API.post('/item', body)
                .then((data) => {
                    setOpen(false);
                    showSnackbar('등록했습니다.');
                    resetForm();
                    // setRows 를 하면 나중에 페이지네이션으로 추가할때 동일한 아이템이 중복으로 들어오므로, 컨트롤이 어렵다.
                    // 앞으로는 등록이든 삭제든, 페이지네이션인 경우에는 전체 reload 를 하는게 맞는 것 같다.
                    reload();
                })
                .finally(() => { setSubmitting(false); })
            }
            else {
                const body = {
                    spaceId: space.id,
                    data: {
                        ...values,
                        price: values.price ? +values.price : null
                    },
                }
                API.put('/item', body)
                .then(() => {
                    const body = { spaceId: space.id };
                    return API.put(`/item/${item.id}/sync-valsummaries`, body)
                })
                .then(() => {
                    setOpen(false);
                    showSnackbar('수정했습니다.');
                    setRows(prevs => {
                        const result = [
                            ...prevs
                        ]

                        const idx = prevs.findIndex(el => el.id == itemId)
                        result.splice(idx, 1, values);
                        
                        return result;
                    })
                })
                .finally(() => { setSubmitting(false); })
            }
        },
    });
    const { values, handleChange, handleBlur, setFieldValue, isSubmitting, handleSubmit, touched, errors, getFieldProps } = formik;

    const nameInputRef = useRef();

	return (
        <Dialog fullWidth={true} maxWidth={'xs'} open={open} onClose={() => { setOpen(false); }}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{item ? '제품 수정' : '제품 등록'}</DialogTitle>

                <DialogContent>
                    <Stack spacing={1} my={2}>
                        <Box>
                            <div className="input-group input-group-sm">
                                <span className="input-group-text" style={{ width: LabelPx }}>제품명&nbsp;<span style={{ fontSize: '.9em', color: colors.secondaryDark }}>(필수)</span></span>
                                <input
                                    ref={nameInputRef}
                                    className="form-control"
                                    {...getFieldProps('name')}
                                />
                            </div>
                            <ErrorText error={touched.name && errors.name} />
                        </Box>
                        
                        <Box>
                            <div className="input-group input-group-sm">
                                <span className="input-group-text" style={{ width: LabelPx }}>제품코드</span>
                                <input
                                    className="form-control"
                                    {...getFieldProps('code')}
                                />
                            </div>
                            <ErrorText error={touched.code && errors.code} />
                        </Box>

                        <Box>
                            <div className="input-group input-group-sm">
                                <span className="input-group-text" style={{ width: LabelPx }}>제품가격</span>
                                <input
                                    className="form-control"
                                    {...getFieldProps('price')}
                                />
                            </div>
                            <ErrorText error={touched.code && errors.code} />
                        </Box>

                        {item && <Box>
                            <div className="input-group input-group-sm">
                                <span className="input-group-text" style={{ width: LabelPx }}>순번</span>
                                <input
                                    className="form-control"
                                    {...getFieldProps('order')}
                                />
                            </div>
                            <ErrorText error={touched.order && errors.order} />
                        </Box>}
                        
                        {/* 속성 */}
                        {props.map(prop => <ValueInput key={prop.id} prop={prop} props={values.props} setFieldValue={setFieldValue} />)}

                        {/* 창고 내 수량 */}
                        {values.places.map(place => <PlaceInput key={place.placeId} values={values} place={place} setFieldValue={setFieldValue} />)}
                    </Stack>
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={() => { setOpen(false); }}>취소</Button>
                    <CircularButton variant='contained' type="submit" loading={isSubmitting}>확인</CircularButton>
                </DialogActions>
            </form>
        </Dialog>
	);
}

export default ItemFormModal;