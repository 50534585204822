import React, { useMemo } from 'react';
import { Box, Chip, Stack } from '@mui/material';
import MembersAuths from './MembersAuths';
import { AuthCode, spaceAuthCodes } from 'constants/auth-codes.constants';
import Title from 'components/Title';
import styles from 'styles.module.css';
import InvitationRequests from 'views/space/InvitationRequests';
import useSpaceAuthinfo from 'hooks/useSpaceAuthinfo';
import Nodata from 'components/Nodata';


const SpaceAuthPage = () => {
	const spaceAuthinfo = useSpaceAuthinfo();

	const myAuths = useMemo(() => {
		if (spaceAuthinfo) return Object.entries(spaceAuthinfo).filter(([key, value]) => value).map(el => el[0]);
	}, [ spaceAuthinfo ]);

	const isOwner = useMemo(() => {
        if (spaceAuthinfo) {
            return !!spaceAuthinfo[AuthCode.spaceOwner];
        }
    }, [ spaceAuthinfo ]);

	if (!myAuths) return null;

	return (
		<Box className={styles.page}>
			<MembersAuths />

			{/* 나의 권한 */}
			<Box sx={{ mt: 5 }}>
				<Title>나의 권한</Title>
				<Stack sx={{ mt: 1 }} direction="row" spacing={1}>
					{myAuths.map(auth => <Chip size='small' key={auth} label={spaceAuthCodes.find(el => el.key === auth).name_kr}>
					</Chip>)}
				</Stack>
				{myAuths.length === 0 && <Nodata>권한이 없습니다.</Nodata>}
			</Box>

			{isOwner && <InvitationRequests />}
		</Box>
	);
}

export default SpaceAuthPage;