import React, {  } from 'react';
import { Box, Link as MuiLink } from '@mui/material';
import { Link, Outlet } from 'react-router-dom';
import Guide from 'components/Guide';
import colors from 'constants/colors';



const TraderReleaseStatistics = () => {
	return (
		<Box style={{ display: 'flex', flexDirection: 'column' }}>
            <MuiLink component={Link} to="amount">기간 내 총판매량 보기</MuiLink>
            <MuiLink component={Link} to="trend">기간 내 판매량 추이 보기</MuiLink>

            <Guide style={{ marginTop: '16px' }}>판매량은 <span style={{ color: colors.secondaryDark, fontWeight: 'bold' }}>출고</span>액션의 수량으로 계산됩니다.</Guide>

            <Box sx={{ mt: 3 }}>
                <Outlet />
            </Box>
		</Box>
	);
}

export default TraderReleaseStatistics;