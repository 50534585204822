import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, Typography, Link as MuiLink, Dialog, DialogContent, DialogActions, DialogTitle, Button } from '@mui/material';
import styles from 'styles.module.css';
import { AuthCode } from 'constants/auth-codes.constants';
import Title from 'components/Title';
import API from 'api';
import { AppContext } from 'contexts/app.context';
import { Link, useNavigate } from 'react-router-dom';
import Guide from 'components/Guide';
import { date_pipe, datetime_pipe, plan_name_pipe } from 'pipes';
import colors from 'constants/colors';
import { useFetchSpace, useSpace } from 'contexts/space_v2.context';
import useSpaceAuthinfo from 'hooks/useSpaceAuthinfo';


const SpacePage = () => {
    const navigate = useNavigate();

    const { showSnackbar, showDialog } = useContext(AppContext);

    const space = useSpace();
    const fetchSpace = useFetchSpace();
    const spaceAuthinfo = useSpaceAuthinfo();

    const owner = useMemo(() => {
        if (space) {
            return space.users.find(user => {
                return !!user.auths.find(auth => auth.code === AuthCode.spaceOwner && auth.refId === space.id);
            })
        }
    }, [ space ]);

    const isOwner = useMemo(() => {
        if (spaceAuthinfo) {
            return !!spaceAuthinfo[AuthCode.spaceOwner];
        }
    }, [ spaceAuthinfo ]);

    const [ billingSomeProblem, setBillingSomeProblem ] = useState(false);
    useEffect(() => {
        if (isOwner) {
            API.get('/plan/my-tossbillings')
            .then(billings => {
                const primaryBilling = billings.find(el => el.isPrimary);
                if (primaryBilling?.state === 'invalid') {
                    setBillingSomeProblem(true);
                }
            })
        }
        return () => { setBillingSomeProblem(false); }
    }, [ space, isOwner ]);

    const onSpaceBackupClick = () => {
        navigate('/space/space-backup-log');
    }

    const handlePlanDismiss = () => {
        showDialog({
            message: '플랜을 해지 하시겠습니까?\n다음 결제일부터 플랜이 해지되고 그 전날까지 플랜혜택이 유지됩니다.',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete(`/plan/dismiss-plan/${space.id}`)
                    .then(() => {
                        showSnackbar('플랜 해지 요청되었습니다.');
                        fetchSpace();
                    })
                }
            },
            second: {
                text: '취소',
            }
        });
    }

    const [ orders, setOrders ] = useState();
    const [ orderModalOpen, setOrderModalOpen ] = useState(false);
    const handleOrderShow = () => {
        API.get(`/plan/space-orders?spaceId=${space.id}`)
        .then(result => {
            setOrders(result);
            setOrderModalOpen(true);
        });
    }

    const handleRemoveSpace = () => {
        showDialog({
            message: '삭제시 복원할 수 없습니다. 정말 삭제하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete(`/space/${space.id}`)
                    .then((data) => {
                        showSnackbar('스페이스가 삭제되었습니다.');
                        navigate('/');
                    })
                }
            },
            second: {
                text: '취소',
            }
        });
    }

    const leaveSpace = () => {
        showDialog({
            title: '스페이스 탈퇴',
            message: '해당 스페이스와 관련된 나의 권한이 모두 삭제됩니다. 정말 탈퇴하시겠습니까?',
            first: {
                text: '확인',
                onClick: () => {
                    API.delete(`/user/leave-space/${space.id}`)
                    .then(() => {
                        showSnackbar('스페이스를 탈퇴했습니다.');
                        navigate('/');
                    });
                }
            },
            second: {
                text: '취소',
            }
        });
    }

    if (!space) return null;

	return (
		<Box className={styles.page}>
            <Title>일반정보</Title>
            <div className={styles.table} style={{ marginTop: '24px' }}>
                <div className={styles.row}>
                    <div className={styles.label}>
                        <Typography variant="body2" className={styles.labelText}>스페이스명</Typography>
                    </div>
                    <div className={styles.content}>
                        <Typography variant="body2">{space.name}</Typography>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>
                        <Typography variant="body2" className={styles.labelText}>스페이스 코드</Typography>
                    </div>
                    <div className={styles.content}>
                        <Typography variant="body2">{space.uuid}</Typography>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>
                        <Typography variant="body2" className={styles.labelText}>소유자</Typography>
                    </div>
                    <div className={styles.content}>
                        <Typography variant="body2">{owner?.nickname || '-'}</Typography>
                    </div>
                </div>
                <div className={styles.row}>
                    <div className={styles.label}>
                        <Typography variant="body2" className={styles.labelText}>금액단위</Typography>
                    </div>
                    <div className={styles.content}>
                        <Typography variant="body2">{space.priceUnit === 'KRW' ? '원 (KRW)' : space.priceUnit}</Typography>
                    </div>
                </div>
            </div>

            <Box sx={{ mt: 5 }}>
                <Title>플랜</Title>
                <div className={styles.table} style={{ marginTop: '24px' }}>
                    <div className={styles.row}>
                        <div className={styles.label}>
                            <Typography variant="body2" className={styles.labelText}>플랜</Typography>
                        </div>
                        <div className={styles.content}>
                            <Typography variant="body2">{plan_name_pipe(space.plan.plan)}</Typography>
                        </div>
                    </div>
                    {isOwner && space.plan.plan !== 'basic' && <>
                        <div className={styles.row}>
                            <div className={styles.label}>
                                <Typography variant="body2" className={styles.labelText}>플랜 시작일</Typography>
                            </div>
                            <div className={styles.content}>
                                <Typography variant="body2">{date_pipe(space.plan.subscribedAt)}</Typography>
                            </div>
                        </div>
                        <div className={styles.row}>
                            <div className={styles.label}>
                                <Typography variant="body2" className={styles.labelText}>다음 결제일자</Typography>
                            </div>
                            <div className={styles.content}>
                                <Typography variant="body2">{space.plan.nextPayDate}</Typography>
                            </div>
                        </div>
                    </>}
                    {isOwner && space.plan.plan !== 'basic' && space.plan.requestStopAt && <>
                        <div className={styles.row}>
                            <div className={styles.label}>
                                <Typography variant="body2" className={styles.labelText}>플랜 해지 대기</Typography>
                            </div>
                            <div className={styles.content}>
                                <Typography variant="body2" style={{ color: colors.secondary }}>다음결제일에 결제되지 않고 플랜이 해지됩니다.</Typography>
                            </div>
                        </div>
                    </>}
                    {isOwner && space.plan.plan === 'basic' && space.plan.stoppedAt && <>
                        <div className={styles.row}>
                            <div className={styles.label}>
                                <Typography variant="body2" className={styles.labelText}>플랜이 해지된 일시</Typography>
                            </div>
                            <div className={styles.content}>
                                <Typography variant="body2">{datetime_pipe(space.plan.stoppedAt)}</Typography>
                            </div>
                        </div>
                    </>}
                    {billingSomeProblem && <>
                        <div className={styles.row}>
                            <div className={styles.label}>
                                <Typography variant="body2" className={styles.labelText}>결제방법상태</Typography>
                            </div>
                            <div className={styles.content} style={{ display: 'flex', alignItems: 'center' }}>
                                <Typography variant="body2" style={{ color: colors.secondary }}>최근 결제에 실패한 내역이 있습니다.</Typography>
                                {billingSomeProblem && <MuiLink sx={{ ml: 1 }} variant='body2' component={Link} to={'/?tab=myaccount'}>확인하기</MuiLink>}
                            </div>
                        </div>
                    </>}
                </div>
            </Box>
            
            {isOwner && <Box sx={{ mt: 1 }}>
                <Button variant="outlined" size="small" onClick={handleOrderShow}>결제내역 보기</Button>
                <Button sx={{ ml: 1 }} variant="outlined" size="small" onClick={() => { navigate('/space/billing/usage'); }}>사용량 보기</Button>
                {space.plan.plan !== 'basic' && !space.plan.requestStopAt && <Button onClick={handlePlanDismiss} variant='outlined' color='secondary' size="small" sx={{ ml: 1 }}>플랜 해지하기</Button>}
            </Box>}

            {/* 백업로그 */}
            {isOwner && space?.plan.plan !== 'basic' && <Box sx={{ mt: 5 }}>
                <Title>스페이스 백업</Title>
                <Box sx={{ mt: 2 }}><Button onClick={onSpaceBackupClick} variant='contained'>백업 로그 확인하기</Button></Box>
            </Box>}

            {/* 스페이스 삭제 */}
            {isOwner && <Box sx={{ mt: 5 }}>
                <Title>스페이스 삭제</Title>
                <Guide style={{ marginTop: '16px' }}>삭제시, 스페이스와 관련된 제품, 창고, 액션 등 모든 정보가 삭제됩니다.</Guide>
                <Box sx={{ mt: 2 }}><Button onClick={handleRemoveSpace} variant='contained' style={{ backgroundColor: 'red' }}>스페이스 삭제하기</Button></Box>
            </Box>}

            {/* 스페이스 탈퇴 */}
            {!isOwner && <Box sx={{ mt: 5 }}><Button variant='contained' style={{ backgroundColor: 'red' }} onClick={leaveSpace}>스페이스 탈퇴하기</Button></Box>}

            {/* 스페이스 주문 내역 */}
            {orders && <Dialog
                fullWidth
                maxWidth={'xs'}
                open={orderModalOpen}
                onClose={() => { setOrderModalOpen(false); }}
            >
                <DialogTitle>결제내역</DialogTitle>
                <DialogContent>
                    {orders.map(order => <Box key={order.id} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography variant='body2'>금액: {order.amount} 원</Typography>
                        <Typography variant='body2'>{datetime_pipe(order.createdAt)}</Typography>
                    </Box>)}
                    {orders.length === 0 && <Typography variant='body2' style={{ color: colors.textSecondary }}>내용이 없습니다.</Typography>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => { setOrderModalOpen(false); }}>닫기</Button>
                </DialogActions>
            </Dialog>}
        </Box>
	);
}

export default SpacePage;