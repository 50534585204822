import { useEffect, useRef, useState } from "react";
import API from "api";


export default function useGetActionSum(params, pageTrigger) {
    const lastId = useRef(null);

    const [ rows, setRows ] = useState([]);
    const [ resultParams, setResultParams ] = useState(null);
    const [ loading, setLoading ] = useState(false);
    const [ mayMore, setMayMore ] = useState(false);
    const [ fetched, setFetched ] = useState(false);

    useEffect(() => {
        setRows([]);
        setResultParams(null);
        setFetched(false);
        setMayMore(true);
        lastId.current = null;
    }, [ params ]);

    useEffect(() => {
        if (!params) return;
        if (!mayMore) return;

        let controller = new AbortController();
        setLoading(true);

        const params_a = {
            ...params,
            lastId: lastId.current,
        };
        
        API.get('/item/asset/action-sum', { params: params_a, signal: controller.signal })
        .then(data => {
            const count = data.items.length;
            setRows(prevs => prevs.concat(data.items));
            setResultParams(data.params);
            setMayMore(count === data.params.limit);
            setFetched(true);
            if (count > 0) lastId.current = data.items[count - 1].id;
        })
        .finally(() => { setLoading(false); });
        
        return () => { controller.abort(); };
    }, [ params, pageTrigger, mayMore ]);

    return { loading, rows, resultParams, mayMore, fetched };
}