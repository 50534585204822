import React from 'react';
import { Box, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';
import colors from 'constants/colors';
import Guide from 'components/Guide';



const AssetPage = () => {
	return (
		<Box style={{ display: 'flex', flexDirection: 'column' }}>
            <MuiLink component={Link} to="action-sum">기간 내 매입∙매출 조회</MuiLink>
            {/* <MuiLink component={Link} to="trend">기간내 판매량 추이 보기</MuiLink> */}

			<Guide style={{ marginTop: '16px' }}>이 페이지는 <span style={{ color: colors.secondaryDark, fontWeight: 'bold' }}>자산 조회</span> 권한이 있는 사용자만 볼 수 있습니다. <br /> 회원 권한은 <MuiLink component={Link} to={'/space/account/auth'}>회원 및 권한 페이지</MuiLink> 에서 설정할 수 있습니다.</Guide>

            <Guide style={{ marginTop: '16px' }}>자산 통계는 <span style={{ color: colors.secondaryDark, fontWeight: 'bold' }}>가격</span>이 설정된 제품에 한해서 조회됩니다. <br /><MuiLink component={Link} to={'/space/schema/item'}>데이터 관리 - 제품 페이지</MuiLink> 에서 제품 가격을 설정할 수 있습니다. </Guide>
		</Box>
	);
}

export default AssetPage;