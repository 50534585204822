import React, { useState, useContext, useMemo } from 'react';
import { Box, Typography, Dialog, DialogTitle, DialogActions, Button, DialogContent, DialogContentText, Checkbox, FormControlLabel, Divider, useMediaQuery, useTheme } from '@mui/material';
import { AppContext } from 'contexts/app.context';
import API from 'api';
import colors from 'constants/colors';
import CircularButton from 'components/CircularButton';
import { AuthCode } from 'constants/auth-codes.constants';
import { useFetchSpace, useSpace } from 'contexts/space_v2.context';
import useSpaceAuthinfo from 'hooks/useSpaceAuthinfo';


const MemberAuthModal = ({ user, open, handleClose }) => {
    const { showSnackbar } = useContext(AppContext);
    const space = useSpace();
    const spaceAuthinfo = useSpaceAuthinfo();
    const fetchSpace = useFetchSpace();

    const amIOwner = useMemo(() => {
        return spaceAuthinfo && spaceAuthinfo[AuthCode.spaceOwner];
    }, [ spaceAuthinfo ]);

    const have_auth_pipe = (authcode) => {
        return !!user.auths.find(auth => auth.code === authcode && auth.refId === space.id);
    }

    const [ values, setValues ] = useState(() => {
        const grantables = [ 
            AuthCode.schemaWritable,
            AuthCode.actionStore,
            AuthCode.actionRelease,
            AuthCode.actionAdjustment,
            AuthCode.actionMove,
            AuthCode.actionConfirmable,
            AuthCode.assetView,
        ];

        const result = {};
        const userIsOwner = have_auth_pipe(AuthCode.spaceOwner);
        
        for (let [key, value] of Object.entries(AuthCode)) {
            if (grantables.indexOf(value) === -1) continue;
            if (userIsOwner) result[value] = true;
            else result[value] = !!user.auths.find(auth => auth.code === value && auth.refId === space.id);
        }
        return result;
    });

    const [ submitLoading, setSubmitLoading ] = useState(false);
    const handleSubmit = () => {
        setSubmitLoading(true);
        const body = {
            spaceId: space.id,
            userId: user.id,
            data: values,
        };

        API.put('/user/update-auths-as-spaceowner', body)
        .then(() => {
            showSnackbar('업데이트했습니다.');
            fetchSpace();
        })
        .finally(() => { setSubmitLoading(false); });
    }

    const theme = useTheme();
    const isHandset = useMediaQuery(theme.breakpoints.down('sm'));

    const Item = ({ code, label, description }) => (<Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
        <FormControlLabel sx={{ width: '140px' }} control={<Checkbox 
            checked={values[code]} 
            onChange={event => {
                setValues((values) => {
                    const result = {
                        ...values
                    };
                    result[code] = event.target.checked;
                    return result;
                });
            }}
            disabled={!amIOwner || have_auth_pipe(AuthCode.spaceOwner)}
        />} label={label} />
        <Typography sx={{ flex: 1 }} variant='body2' color="text.secondary">{description}</Typography>
    </Box>);

	return (
        <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
            <form onSubmit={handleSubmit}>
                <DialogTitle>{'권한정보'}</DialogTitle>

                <DialogContent>
                    <DialogContentText>{`${user.nickname} 님의 권한정보입니다.`}</DialogContentText>

                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                        <Box sx={{ py: 3, display: 'flex', flexDirection: isHandset ? 'column' : 'row' }}>
                            <Typography variant='body2' sx={{ mt: 1, width: '100px' }}>소유</Typography>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column'}}>
                                <Box sx={{ flex: 1, display: 'flex', alignItems: 'center' }}>
                                    <FormControlLabel sx={{ width: '140px' }} control={<Checkbox checked={have_auth_pipe(AuthCode.spaceOwner)} disabled />} label="소유" />
                                    <Typography sx={{ flex: 1, }} variant='body1' color="text.secondary">소유자로서 결제계정을 가지고 있습니다.</Typography>
                                </Box>
                            </Box>
                        </Box>
                        
                        <Divider />
                        <Box sx={{ py: 3, display: 'flex', flexDirection: isHandset ? 'column' : 'row' }}>
                            <Typography variant='body2' sx={{ mt: 1, width: '100px' }}>데이터</Typography>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column'}}>
                                <Item 
                                    code={AuthCode.schemaWritable}
                                    label={'데이터 관리'}
                                    description={'거래처, 창고, 제품, 제품 속성을 등록, 수정, 삭제 할 수 있습니다.'}
                                />
                            </Box>
                        </Box>

                        <Divider />
                        <Box sx={{ py: 3, display: 'flex', flexDirection: isHandset ? 'column' : 'row' }}>
                            <Typography variant='body2' sx={{ mt: 1, width: '100px' }}>액션</Typography>
                            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column'}}>
                                <Item 
                                    code={AuthCode.actionConfirmable}
                                    label={'액션 승인'}
                                    description={'작성된 액션을 승인할 수 있습니다.'}
                                />
                                <Item
                                    code={AuthCode.actionStore} 
                                    label={'액션: 입고'}
                                    description={'입고 액션을 작성할 수 있습니다.'}
                                />
                                <Item
                                    code={AuthCode.actionRelease} 
                                    label={'액션: 출고'}
                                    description={'출고 액션을 작성할 수 있습니다.'}
                                />
                                <Item
                                    code={AuthCode.actionMove} 
                                    label={'액션: 이동'}
                                    description={'이동 액션을 작성할 수 있습니다.'}
                                />
                                <Item
                                    code={AuthCode.actionAdjustment} 
                                    label={'액션: 조정'}
                                    description={'조정 액션을 작성할 수 있습니다.'}
                                />
                                <Item
                                    code={AuthCode.assetView} 
                                    label={'자산 조회'}
                                    description={'자산 및 제품 가격 관련 데이터를 조회를 할 수 있습니다.'}
                                />
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button sx={{ color: colors.textSecondary }} type="button" onClick={handleClose}>닫기</Button>
                    {amIOwner && !have_auth_pipe(AuthCode.spaceOwner) && <CircularButton variant='contained' type="submit" onClick={handleSubmit} loading={submitLoading}>수정</CircularButton>}
                </DialogActions>
            </form>
        </Dialog>
	);
}

export default MemberAuthModal;